// Navigation – Desktop

.page_header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.page_header__nav {
  position: relative;
  @apply px-2 pt-6;

  @media #{$medium-up} {
    @apply px-2 max-w-2xl mx-auto;
    display: flex;
    align-items: center;

    @supports(display: grid) {
      display: grid;
      grid-template-columns: 1fr 12rem 1fr;
      grid-template-rows: 1fr;

      @screen lg {
        grid-template-columns: 1fr 16rem 1fr;
      }
    }
  }
}

//
// mobile open and close button
.page_header__nav__menu_btn {
  @apply text-white absolute p-4 z-100;
  top: 50%;
  right: 5px;
  transform: translateY(-25%);

  @media #{$medium-up} {
    display: none;
  }
}


//
// Logo
.page_header__nav__logo {
  @apply block max-w-tiny;

  @media #{$tiny-only}, #{$small-only} {
    @apply mx-auto w-1/2;
  }

  @media #{$medium-up} {
    @supports(display: grid) {
      z-index: 999;
      grid-column: 2/3;

      @screen lg {
        grid-row: 1/2;
      }
    }
  }
}

//
// a wrapper for showing/hiding the menu with
.page_header__mobile_wrapper {
  @media #{$tiny-only}, #{$small-only} {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    @apply bg-grey-darkest pt-6 pb-8;
  }

  @media #{$medium-up} {
    display: block;
    width: 100%;

    @supports(display: grid) {
      grid-column: 1/4;
      grid-row: 1/2;
    }
  }
}

// show the menu on mobile
.mobile_open.page_header__mobile_wrapper {
  @media #{$tiny-only}, #{$small-only} {
    display: block;
    position: absolute;
  }
}

//
// Main Menu list
.page_header__nav__menu {
  @apply list-none p-0 mb-6;

  a {
    @apply text-grey-lighter no-underline uppercase;
  }

  // mobile only menu styles
  @media #{$tiny-only}, #{$small-only} {
    @apply bg-grey-darker flex flex-wrap uppercase text-lg;

    li {
      width: 50%;

      &:not(:last-child) {
        @apply border-grey-darkest;
      }

      &:nth-child(odd) {
        @apply border-grey-darkest;
      }
    }

    a {
      @apply block mb-0 text-grey-light p-4;

      &:hover {
        @apply bg-grey-dark text-grey-lighter;
      }
    }
  }

  @media #{$medium-up} {
    display: flex;
    justify-content: space-around;

    @supports(display: grid) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      justify-items: center;


    }
  }

  @screen lg {
    grid-template-columns: 1fr 1fr 1fr 16rem 1fr 1fr 1fr;

    li:nth-child(4) {
      grid-column: 3/4;
    }

    li:nth-child(5) {
      grid-column: 5/6;
    }
  }
}

//
// Homepage menu modifications
.page_header__nav--homepage {
  @apply pt-8;

  .page_header__nav__menu {
    @screen lg {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

      li:nth-child(4) { grid-column: auto; }
      li:nth-child(5) { grid-column: auto; }
    }
  }
}
