//
// Allows us to add additional transitions to buttons
@mixin btn_transition($transitions...) {
  @if $transitions {
    transition: color 0.3s $base-transition,
                background-color 0.3s $base-transition,
                border-color 0.3s $base-transition,
                width 0.2s $base-transition,
                $transitions;
  } @else {
    transition: color 0.3s $base-transition,
                background-color 0.3s $base-transition,
                border-color 0.3s $base-transition,
                width 0.2s $base-transition;
  }
}

.btn {
  @apply font-sans font-thin text-white antialiased bg-blue border border-blue-dark px-4 py-3;
  display: inline-block;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  appearance: none;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border-radius: 0;
  line-height: 1;
  cursor: pointer;
  user-select: none;
  @include btn_transition();

  &:hover {
    @apply text-white border-blue-darker bg-blue-dark;
  }

  > svg {
    @apply ml-2;
    margin-top: -0.125em;
  }
}

// .btn--icon {
//   display: inline-flex;
//   align-items: center;
//   flex-direction: row;
//   justify-content: center;
//   text-align: left;

//   svg {
//     margin-left: 8px;
//     width: 24px;
//     height: 24px;
//   }
// }

// Button styles
.btn--primary {
  @apply bg-blue text-white;

  &:hover {
    @apply bg-blue-darker;
  }
}

.btn--secondary {
  @apply inline-flex items-center text-orange-darkest bg-cream-darkest px-3 py-1 text-sm uppercase no-underline;

  &:hover {
    @apply bg-cream-darkester;
  }
}

//
// Button layout
